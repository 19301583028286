// @flow

import React from 'react';

import Link from '../Link';

export default function withHtml(node: Object, index: number) {

  // TODO: remove base URL of postseal.com
  if (node.type === 'tag' && node.name === 'a' && (!node.attribs || !node.attribs.style)) {
    const {children, attribs} = node;
    const {href, ...other} = attribs;
    return <Link {...other} key={href} to={href} rel="noopener noreferrer" target='_blank'>{children && children[0].data}</Link>
  }
}
