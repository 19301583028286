// @flow

import React, {useLayoutEffect, useRef} from 'react';
import {graphql, navigate} from 'gatsby';

import AntdContainer from '../components/AntdContainer';
import {Collapse} from 'antd';
import HtmlHead from '../components/HtmlHead';
import ReactHtmlParser from 'react-html-parser';
import withHtml from '../components/hoc/withHtml';

const {Panel} = Collapse;

export const query = graphql`
  query FaqQuery {
    allContentfulFaqEntry {
      edges {
        node {
          title
          slug
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

const scrollToRef = (ref) => ref.current && window.scrollTo(0, ref.current.offsetTop);

const FAQView = (props: Object) => {

  //const pageScrollRestoration = useScrollRestoration(`faq-page`);
  const activePanelRef = useRef(null);
  useLayoutEffect(() => {
    // hack for a scroll - doesn't work without a timeout
    setTimeout(() => {
      scrollToRef(activePanelRef);
    }, 50);
  }, []);


  const {data, location} = props;

  const mainEntity = data.allContentfulFaqEntry.edges.map(({node}) => {
    return {
      "@type": "Question",
      "name": node.title,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": node.body.childMarkdownRemark.html
      }
    }
  });
  const extraStructData = {
    "@type": "FAQPage",
    "mainEntity": mainEntity
  };
  const keywords = data.allContentfulFaqEntry.edges.map(({node}) => `${node.title}`);

  return (
    <div className='section light'>
      <HtmlHead title='Frequently Asked Questions | PostSeal' extraStructData={extraStructData} meta={{keywords}}/>
      <div className='content-wrapper'>
        <AntdContainer>
          <h1 className='ant-typography page-title'>Frequently Asked Questions</h1>
            <Collapse defaultActiveKey={location.search} accordion onChange={search => {
                if (search) {
                  navigate(`/faq${search}`, {replace: true});
                } else {
                  navigate(`/faq`, {replace: true});
                }
              }}>
            {
              data.allContentfulFaqEntry.edges.map(({node}) => {
                const search = `?question=${node.slug}`;

                return (
                  <Panel header={node.title} key={search}>
                    <div ref={activePanelRef}>
                      {
                        ReactHtmlParser(node.body.childMarkdownRemark.html, {transform: withHtml})
                      }
                    </div>
                  </Panel>
                );
              })
            }
            </Collapse>
        </AntdContainer>
      </div>
    </div>
  );
}

export default FAQView;
